<template>
    <li class="text-center rounded font-medium bg-white rounded hover:bg-indigo-100 mb-1 border-2 border-indigo-100">
        <router-link :to="{ name: 'tagpost', params: { tagname: tagname, tagid: tagid } }">
            <div class="flex items-center p-3">                 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                </svg>
                <p>&nbsp;&nbsp;{{ tagname }}</p>
            </div>
        </router-link>
    </li>
</template>

<script setup>
    import { defineProps, ref} from "vue"

    const props = defineProps({
        tagname: String,
        tagid: Number
    })

    const tagname = ref(props.tagname)
    const tagid = ref(props.tagid)

</script>
